<template>
  <el-dialog
    :visible.sync="visible"
    width="480px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div slot="title">
      确认开票 <span class="orange" v-if="selection.length > 1">（已选择 {{selection.length}} 项；总计金额 {{amount}} 元）</span>
    </div>
    <div class="text-center msg">
      {{msg}}
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { confirm_invoice } from '@/api/finance'
export default {
  props: {
    visible: {
      type: Boolean
    },
    selection: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    msg() {
      if (this.selection.length == 1) {
        return '是否确认开票？'
      } else {
        return '是否确认批量开票？'
      }
    },
    amount() {
      let amount = 0
      this.selection.map(o => {
        amount += o.invoiceAmount * 100
      })
      return amount / 100
    }
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    },
    confirm() {
      let params = new FormData()
      this.selection.map(o => {
        params.append('invoiceRecordIds', o.id)
      })
      confirm_invoice(params).then(() => {
        this.$message.success('开票成功')
        this.$emit('confirm')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.orange{
  color: #EE9656;
}
.msg{
  font-size: 18px;
}
</style>