<template>
  <div :style="{'padding-bottom': selection.length ? '48px' : ''}">
    <div class="page-content">
      <div class="page-sub-title">
        筛选查询
      </div>
      <div>
        <div class="search-item">
          <span class="label">申请时间</span>
          <el-date-picker
            size="small"
            style="width:370px"
            v-model="searchParams.date"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" clearable>
          </el-date-picker>
        </div>
        <div class="search-item">
          <span class="label">申请开票类型</span>
          <el-select v-model="searchParams.applyInvoiceType" clearable placeholder="请选择" size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in invoiceOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">发票类型</span>
          <el-select v-model="searchParams.invoiceType" clearable placeholder="请选择" size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in invoiceTypeOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">开票状态</span>
          <el-select v-model="searchParams.invoiceStatus" clearable placeholder="请选择" size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in invoiceStatusOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="search-item">
          <span class="label">收件方式</span>
          <el-select v-model="searchParams.receiverType" clearable placeholder="请选择" size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in receiverTypeOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div> -->
        <div class="search-item">
          <span class="label">酒店名称</span>
          <el-input v-model="searchParams.hotelName" clearable size="small" style="width: 400px" placeholder="请输入酒店名称"></el-input>
        </div>
        <!-- <div class="search-item">
          <span class="label">联系方式</span>
          <el-input v-model="searchParams.mobile" clearable size="small" style="width: 160px" placeholder="请输入联系方式"></el-input>
        </div> -->
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title">
        开票管理
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        ref="invoiceTable"
        border
        header-row-class-name="table-header"
        row-key="id"
        :row-class-name="rowSelectedClassName"
        @selection-change="handleSelect"
      >
        <el-table-column
          type="selection"
          align="center"
          :selectable="checkboxValid"
          :reserve-selection="true"
          width="55">
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelName"
          label="酒店名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="applyInvoiceType"
          label="申请开票类型"
        >
          <template slot-scope="scope">
            {{invoiceMap[scope.row.applyInvoiceType]}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="invoiceAmount"
          label="开票金额（元）"
        >
          <template slot-scope="scope">
            {{$commaNum(scope.row.invoiceAmount)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="invoiceType"
          label="发票类型"
        >
          <template slot-scope="scope">
            {{invoiceTypeMap[scope.row.invoiceType]}}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="receiver"
          label="客户名称"
        ></el-table-column> -->
        <!-- <el-table-column
          align="center"
          prop="receiverPhone"
          label="联系方式"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="receiverType"
          label="收件方式"
        >
          <template slot-scope="scope">
            {{receiverTypeMap[scope.row.receiverType]}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="申请时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="invoiceStatus"
          label="开票状态"
        >
          <template slot-scope="scope">
            <span class="point" :style="{'background': InvoiceStatusColor[scope.row.invoiceStatus]}"></span>
            {{invoiceStatusMap[scope.row.invoiceStatus]}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="updateTime"
          label="开票时间"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showInvoice(scope.row)" v-if="checkboxValid(scope.row)">开票</el-button>
            <el-button type="text" @click="showDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>
    
    <div class="invoice-fixed flex flex-between" v-if="selection.length">
      <div>
        <span class="blue">已选择 {{selection.length}} 项</span>
        <span class="grey">总计金额：{{amount}} 元</span>
      </div>
      <div>
        <el-button size="small" @click="cancelSelection()">取消选择</el-button>
        <el-button type="primary" size="small" @click="showInvoice()">批量开票</el-button>
      </div>
    </div>

    <invoice-dialog :visible="invoiceVisible" :selection="selectionDlg" @close="hideInvoice" @confirm="confirmInvoice"></invoice-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { pageMixin } from '@/mixins/pageMixin'
import { InvoiceStatusColor } from '@/config/finance-config'
import { get_invoice_list } from '@/api/finance'
import { get_key_value } from '@/api/common'
import invoiceDialog from './component/invoice-dialog'

export default {
  name: 'finance-invoice',
  mixins: [pageMixin],
  components: {
    invoiceDialog
  },
  data() {
    return {
      InvoiceStatusColor,
      invoiceOption: [],
      invoiceTypeOption: [],
      invoiceStatusOption: [],
      receiverTypeOption: [],
      invoiceMap: {},
      invoiceTypeMap: {},
      invoiceStatusMap: {},
      receiverTypeMap: {},
      searchParams: {
        date: [],
        applyInvoiceType: '',
        invoiceType: '',
        receiverType: '',
        invoiceStatus: '',
        hotelName: '',
        mobile: ''
      },
      selection: [],
      invoiceVisible: false,
      selectionDlg: []
    }
  },
  computed: {
    ...mapState(['userInfo']),
    amount() {
      let amount = 0
      this.selection.map(o => {
        amount += o.invoiceAmount * 100
      })
      return amount / 100
    }
  },
  activated() {
    if (this.$store.state.page.keepAlive.indexOf('finance-invoice') < 0 || this.list.length == 0) {
      Object.assign(this.$data, this.$options.data())
      this.getOption()
      this.getList()
    }
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'APPLY_INVOICE_TYPE'
      }).then(res => {
        this.invoiceOption = res ? res.records : []
        this.invoiceMap = this.$arrayToMap(this.invoiceOption)
      })
      get_key_value({
        type: 'INVOICE_TYPE'
      }).then(res => {
        this.invoiceTypeOption = res ? res.records : []
        this.invoiceTypeMap = this.$arrayToMap(this.invoiceTypeOption)
      })
      get_key_value({
        type: 'CHECK_INVOICE_STATUS'
      }).then(res => {
        this.invoiceStatusOption = res ? res.records : []
        this.invoiceStatusMap = this.$arrayToMap(this.invoiceStatusOption)
      })
      get_key_value({
        type: 'RECEIVER_TYPE'
      }).then(res => {
        this.receiverTypeOption = res ? res.records : []
        this.receiverTypeMap = this.$arrayToMap(this.receiverTypeOption)
      })
    },
    async getList() {
      let params = {
        orgId: this.userInfo.fkOrgId,
        ...this.pageParams,
        ...this.searchParams
      }
      params.startTime = params.date && params.date[0] ? params.date[0] : ''
      params.endTime = params.date && params.date[1] ? params.date[1] : ''
      delete params.date
      const res = await get_invoice_list(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    checkboxValid(row) {
      if (row.invoiceStatus == 1) return true
      return false
    },
    handleSelect(selection) {
      console.log(selection)
      this.selection = selection
    },
    cancelSelection() {
      this.$refs.invoiceTable.clearSelection()
    },
    rowSelectedClassName({ row }) {
      const index = this.selection.findIndex(o => { return o.id == row.id })
      if (index > -1) {
        return 'selected-row';
      }
      return '';
    },
    showDetail(row) {
      this.$router.push({
        name: 'finance-invoice-detail',
        query: {
          invoiceDetail: JSON.stringify(row),
          invoiceMap: JSON.stringify(this.invoiceMap),
          invoiceTypeMap: JSON.stringify(this.invoiceTypeMap),
          receiverTypeMap: JSON.stringify(this.receiverTypeMap),
          invoiceStatusMap: JSON.stringify(this.invoiceStatusMap)
        }
      })
    },
    showInvoice(row) {
      if (!row && this.selection.length == 0) {
        return this.$message.warning('请选择开票订单')
      }
      this.selectionDlg = row ? [row] : this.selection
      this.invoiceVisible = true
    },
    hideInvoice() {
      this.selectionDlg = []
      this.invoiceVisible = false
    },
    confirmInvoice() {
      this.getList()
      this.hideInvoice()
      this.cancelSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-fixed{
  position: fixed;
  background: #ffffff;
  bottom: 0;
  right: 0;
  left: 200px;
  z-index: 20;
  box-shadow: 0px -7px 10px 0px rgba(192, 195, 197, 0.29);
  padding: 8px 60px;
  line-height: 32px;
  .blue{
    color: $color;
    font-size: 16px;
  }
  .grey{
    color: $grey;
    margin-left: 22px;
  }
}
</style>